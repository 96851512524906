<template lang="html">
  <section class="new-featured-vertical-img grid" v-if="dataContent">
    <router-link
      :to="{
        name: 'page-entry',
        params: {
          typeContent: this.typeContentUrl,
          slug: this.dataContent.slug,
        },
      }"
      class="content-new"
      title="Enlace a la noticia"
    >
      <span
        v-if="dataContent.url"
        src="/img/img-test.png"
        class="icon-media typeMedia1"
      ></span>
      <img
        v-if="dataContent.image.url"
        :src="dataContent.image.url"
        :alt="dataContent.image_footer"
        class="img-new"
      />
      <!-- <span class="category">ÚLTIMA ENTREVISTA</span> -->
      <div class="info-box">
        <h2 v-if="this.dataContent.name_markdown" class="title">
          <Markdown :source="this.dataContent.name_markdown" />
        </h2>
        <h2 class="title" v-else>{{ this.dataContent.name }}</h2>

        <p class="box-dates">
          <span class="date-text m-right-10-px"
            >{{ currentDateTime(dataContent.publish_date) }}
          </span>
          <span class="author" v-if="dataContent.source">{{
            dataContent.source
          }}</span>
        </p>
        <p class="box-dates">
          <template v-for="theme in dataContent.themes" :key="theme.id">
            <span class="category-tag m-right-10-px">{{ theme.name }}</span>
          </template>
          <span class="category-location" v-if="dataContent.zone">{{
            dataContent.zone.name
          }}</span>
        </p>
        <p class="dest" v-if="dataContent.header">
          {{ CutText(dataContent.header, 100) }}
        </p>
      </div>
    </router-link>
  </section>
</template>

<script lang="js">
import moment from 'moment'
import Markdown from 'vue3-markdown-it';

export default {
  name: 'new-featured-vertical-img',
  props: ['dataContent', 'typeMedia', 'typeContent'],
  components: {
    Markdown
  },
  mounted() {

  },
  data() {
    return {
    }
  },
  methods: {
    currentDateTime(date) {
      return moment(date).locale('es').format('L')
    },
    CutText(texto, limite) {
      var puntosSuspensivos = "...";
      if (texto.length > limite) {
        texto = texto.substring(0, limite) + puntosSuspensivos;
      }

      return texto;
    }

  },
  computed: {

    typeContentUrl() {
      switch (this.typeContent) {
        case 'entry':
          return 'noticia'
        case 'event':
          return 'evento'
        default:
          return 'multimedia'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/general.scss";

.new-featured-vertical-img {
  cursor: pointer;
  height: auto;
  min-height: 480px;

  .content-new {
    text-decoration: none;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    position: relative;
    border-radius: 13px;
    padding-left: 10px;
    margin-bottom: 20px;
    color: $black;

    .category {
      position: absolute;
      left: 0px;
      top: 10px;
      z-index: 2;
      background: $blue;
      font-weight: 900;
      color: white;
      font-size: 16px;
      padding: 5px 15px;
      border-radius: 13px;
      z-index: 0;
    }

    .icon-media {
      width: calc(100% - 10px);
      height: 270px;
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      background-position: center center;
      background-size: 45px;
      background-repeat: no-repeat;
      background-color: rgba(7, 7, 7, 0.5);
      border-radius: 10px;
      bottom: 0;

      &.typeMedia0 {
        background-image: url("/icons/icon-photo.svg");
        background-size: 50px;
      }

      &.typeMedia1 {
        background-image: url("/icons/icon-video.svg");
      }
    }

    .img-new {
      width: 100%;
      border-radius: 13px;
      height: 270px;
      object-fit: cover;
      object-position: center;
    }

    .info-box {
      width: 100%;
      padding: 20px 0px;
    }

    .dest {
      margin-bottom: 5px;
    }

    .box-dates {
      margin-bottom: 5px;
    }
  }
}

@media screen and (max-width: 895px) {
  .content-new .title {
    font-size: 18px;
  }

  .subtitle {
    font-size: 17px;
  }

  .content-new .img-new {
    height: 235px;
  }

  .new-featured-vertical-img .content-new {
    padding-left: 0;
  }

  .new-featured-vertical-img .content-new .info-box {
    padding: 10px 0;
  }

  .new-featured-vertical-img .content-new .icon-media {
    width: 100%;
  }
}
</style>
